const themeSettings = {
	colors: {
		black: 'rgb(28,28,28)',
		white: 'rgb(247,245,242)',
		darkBlue: 'rgba(11, 34, 78, 1)',
		blue: 'rgba(15, 73, 177, 1)',
		lightRed: 'rgba(249, 116, 103, 1)',
		beige: 'rgba(255, 213, 176, 1)',
		buttonBeige: 'rgba(255, 220, 188, 0.5)',
		creamy: 'rgba(245, 236, 225, 1)',
		darkerBlue: 'rgba(35, 41, 52, 1)',
		red: 'rgba(242, 99, 65, 1)',
		sand: 'rgba(206, 190, 170, 1)',
		privacyPolicyGrey: 'rgba(53,65,85,1)'
	},

	fontFamily: {
		main: 'Geomanist, sans-serif',
	},

	fontWeight: {
		thin: 100,
		extraLight: 200,
		light: 300,
		regular: 400,
		book: 500,
		medium: 600,
		bold: 700,
		black: 800,
		ultra: 900,
	},
};

export default themeSettings;
