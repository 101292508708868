import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import themeSettings from '../../styles/themeSettings';
import MainStyle from '../../styles/MainStyles';
import CookiesInfo from '../Cookies/Cookies';

const Layout = ({ children, pageContext }) => {
	const cookiesData = pageContext?.cookies?.[0] || '';
	useEffect(() => {
		const innerHeight = window.innerHeight;
		const innerWidth = window.innerWidth;
		document.documentElement.style.setProperty('--inner-height', `${innerHeight}px`);
		document.documentElement.style.setProperty('--inner-width', `${innerWidth}px`);
	}, []);

	return (
		<ThemeProvider theme={themeSettings}>
			<CookiesInfo data={cookiesData}/>
			<MainStyle />
			{children}
		</ThemeProvider>
	);
};

export default Layout;
