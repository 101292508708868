import React, { Component } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField";

const StyledCookiesInfoWrapper = styled.div`
	//transition: all 0.2s ease-in-out;
	background-color: ${({ theme }) => theme.colors.darkBlue};
	bottom: 20px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	left: 20px;
	max-width: 300px;
	padding: 20px 16px;
	position: fixed;
	row-gap: 14px;
	z-index: 1000;
	&.hidden {
		display: none;
	}
`;

const StyledInfoDescription = styled.div`
	color: ${({ theme }) => theme.colors.white};
	font-size: 11px;
	line-height: 16px;
  	a {
	  color: ${({theme}) => theme.colors.red};
	  text-decoration: none;
    }
`;

const StyledButton = styled.div`
	color: ${({ theme }) => theme.colors.red};
	cursor: pointer;
`;

class CookiesInfo extends Component {
	state = {
		showCookieInfo: false,
		cookiesSettings: {
			necessaryCookies: true,
		},
	};

	componentDidMount() {
		this.setState({ showCookieInfo: Cookies.get('necessaryCookies') === undefined });
	}

	render() {
		const { showCookieInfo, cookiesSettings } = this.state;
		const {data} = this.props;
		const acceptAllButtonText = data?.acceptAllButtonText || '';
		const cookiesMainInfoDescription = data?.cookiesMainInfoDescription || '';
		const toggleModal = (toggleStateName) => {
			switch (toggleStateName) {
				case 'showCookieInfo':
					this.setState((prevState) => ({
						showCookieInfo: !prevState.showCookieInfo,
					}));
					break;
				default:
					break;
			}
		};

		const onAcceptAll = () => {
			toggleModal('showCookieInfo');
			Cookies.set('necessaryCookies', cookiesSettings.necessaryCookies, { expires: 30 });

		};
		return (
			<StyledCookiesInfoWrapper className={!showCookieInfo && 'hidden'}>
				<StyledInfoDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(cookiesMainInfoDescription)}/>
				<StyledButton onClick={onAcceptAll}>{acceptAllButtonText}</StyledButton>
			</StyledCookiesInfoWrapper>
		);
	}
}

export default CookiesInfo;
